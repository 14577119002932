﻿
@import url('https://cdn.rawgit.com/mfd/f3d96ec7f0e8f034cc22ea73b3797b59/raw/856f1dbb8d807aabceb80b6d4f94b464df461b3e/gotham.css');

$gotham: 'GothamPro', sans-serif;
$ibm: 'IBM Plex Sans', sans-serif;
$font-awesome: 'Font Awesome 5 Free';

body {
    font-family: $ibm;
}

h1,
.h1 {
    font-family: $gotham;
    font-size: 2.8rem;
    color: $dark-grey;
    font-weight: 400;
    line-height: 3.9rem;
}

h2,
.h2 {
    font-family: $gotham;
    font-size: 2.4rem;
    color: $dark-grey;
    font-weight: 800;
    line-height: 3.3rem;
}

h3,
.h3 {
    font-family: $gotham;
    font-size: 2.2rem;
    color: $dark-grey;
    font-weight: 400;
    line-height: 3.0rem;
}

h4,
.h4 {
    font-family: $gotham;
    font-size: 2.0rem;
    color: $dark-grey;
    font-weight: 200;
    line-height: 2.8rem;
}

h5,
.h5 {
    font-family: $gotham;
    font-size: 1.8rem;
    color: $dark-grey;
    font-weight: 700;
    line-height: 2.5rem;
}

h6,
.h6 {
    font-family: $gotham;
    font-size: 1.4rem;
    color: $dark-grey;
    font-weight: 700;
    line-height: 2.1rem;
}

p, main ol, main ul, .sf-media-body {
    font-family: $ibm;
    color: $dark-grey;
    font-weight: 200;
    font-size: 1.6rem;
    line-height: 2.4rem;

    &.large {
        font-size: 2.0rem;
        line-height: 3.0rem;
    }

    &.small {
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
}

a {
    transition: $transition;
    color: $accent5;
    text-decoration: none;

    &:focus {
        outline-color: $accent1;
    }

    &:hover {
        color: $accent1;
        text-decoration: underline;
        
    }
}

.white {
    color: $white;
}

.body-color {
    color: $dark-grey;
}

blockquote {
    border-left: 1rem solid $accent1;
    padding: 1.5rem 0 1.5rem 2rem;
}

@media screen and (min-width: 768px) {
    h1,
    .h1 {
        font-size: 4.6rem;
        line-height: 6.1rem;
    }

    h2,
    .h2 {
        font-size: 3.0rem;
        line-height: 4.5rem;
    }

    h3,
    .h3 {
        font-size: 2.6rem;
        line-height: 3.9rem;
    }

    h4,
    .h4 {
        font-size: 2.2rem;
        line-height: 3.3rem;
    }

    h5,
    .h5 {
        line-height: 2.7rem;
    }

    h6,
    .h6 {
        line-height: 2.1rem;
    }
}
