﻿.categories {
    position: relative;

    ul {
        list-style: none;
        margin-block-start: 0;
        padding-inline-start: 2rem;

        li {
            padding: 1rem 0;

            a {
                color: $dark-accent2;
                text-decoration: none;
                transition: $transition;

                &:hover {
                    text-decoration: underline;
                    color: $accent1;
                }
            }
        }
    }

    .show-more {
        display: none;
        text-align: right;
        margin-top: -2rem;

        a {

            &:after {
                content: "\f0d7";
                font-family: $font-awesome;
                display: inline;
                margin-left: 0.5rem;
                font-weight: 900;
            }
        }
    }

    .js-mobile-hide {
        height: 25rem;
        overflow: hidden;
        position: relative;

        & + .show-more {
            display: block;
        }
    }

    .js-mobile-hide:after {
        content: "";
        display: block;
        background: rgba(255,255,255, 0.8);
        height: 10rem;
        position: absolute;
        top: 15.5rem;
        width: 100vw;
        left: 0;
        overflow: hidden;
    }
}


@media screen and (min-width:1080px) {
    .categories {
        .js-mobile-hide {
            height: auto;

            &+ .show-more{
                display: none;
            }

            &:after {
                display: none;
            }
        }

        .show-more {
            display: none;
        }
    }
}
