﻿.portal-profile {
    background: $home-grey-accent;
    margin-bottom: 5rem;
    color: $dark-accent1;

    .container {
        display: flex;

        .profile-container {
            display: flex;
            width: 50%;
            padding: 2.5rem;
            box-sizing: border-box;

            a {
                display: flex;
                color: $dark-accent1;

                .profile-image {
                    max-width: 12.7rem;
                    margin-right: 2rem;

                    img {
                        width: 100%;
                        border-radius: 50%;
                    }
                }

                .profile-info {
                    line-height: 2.3rem;
                    font-size: 1.6rem;
                    align-self: center;

                    p {
                        line-height: 2.3rem;
                    }

                    .name {
                        font-weight: 700;
                        padding-bottom: 0.25rem;
                    }
                    .business-area {
                        text-transform:uppercase;
                        color:$accent3;
                        font-weight:700;
                        margin-bottom: 0.5rem;
                        font-size:1.4rem;
                        letter-spacing: 0.1rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .portal-profile {
        .container {
            //width: 900px;
            display:block !important;
        

        .profile-container {
            width: 100%;

            a {
                .profile-image {
                    max-width: 18.7rem;
                    margin-right: 5rem;

                    img {
                        width: 11rem;
                    }
                }
                .profile-info {
                    width:200px;
                    line-height: 2.3rem;
                    font-size: 1.4rem;
                    align-self: center;
                    }

                }
            }
        }
    

}

}