﻿.Alert-Banner-Container {
    display: flex;
    flex-direction: column;
    background-color: $accent1;
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    .Alert-Detail-Container {
       
    }

    .Alert-Action-Container {
        display: flex;
        width: 100%;
        margin-top: 5%;
        margin-bottom: 5%;
        box-sizing: border-box;

        .button {
            flex-grow: 1;
            padding: 0;
            margin-right: 2%;

            &:last-of-type {
                margin-right: 0;
            }

            a {
                display: flex;
                justify-content: center;
                padding: 1.3rem 0;
            }
        }
    }

    &.wide {
        padding: 2.5rem;

        .Alert-Detail-Container {
            padding-left: 0;
            padding-right: 0;
        }

        .alert-right {
            width: 100%;
        }

        .Alert-Action-Container {
            justify-content: normal;
            padding-left: 0;
            padding-right: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .Alert-Banner-Container {
        flex-direction: row;
        padding-left: 8%;
        padding-right: 8%;
        align-items: center;

        .Alert-Heading-Container {
            width: 33%;
        }

        .Alert-Detail-Container {
            width: 33%;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        .Alert-Action-Container {
            padding-left: 5%;
            width: 33%;
            margin: 0;
            justify-content: space-around;
        }


        &.wide {
            padding-left: 8%;
            padding-right: 8%;

            .container {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0;
                width: 100%;
                max-width: 100%;

                .Alert-Heading-Container {
                    max-width: 100%;

                    h1, h2, h3, h4, h5 {
                        margin-top: 0;
                    }
                }

                .alert-left {
                    width: 66%;

                    .Alert-Heading-Container {
                        width: 100%;
                    }
                }

                .Alert-Detail-Container {
                    width: 100%;
                }

                .alert-right {
                    width: 33%;

                    .Alert-Action-Container {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1280px) {

    .Alert-Banner-Container {
        &.wide {
            .container {
                .alert-left {
                    max-width: 80%;
                }
            }
        }
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .Alert-Banner-Container {
        z-index: 50;
        position: absolute;
        width: 100%;
    }
}
