﻿#map {
    height: 30rem;
    transition: $transition;

    &.expanded {
        height: 50rem;
    }

    .gm-style {
        .gm-style-iw-a {
            .gm-style-iw-t {
                .gm-style-iw {
                    border-radius: 0;
                    padding: 0 !important;
                    min-height: 18rem;

                    .gm-style-iw-d {
                        overflow: visible !important;

                        .location-popup-content,
                        .profile-popup-content {
                            display: flex;
                            padding-top: 2rem;
                            padding-bottom: 1rem;
                            padding-left: 1.5rem;
                            min-height: 10rem;
                            overflow: auto;
                            min-width: 21rem;
                            max-height: 228px;

                            i {
                                color: $accent1;
                                font-size: 2.5rem;
                                margin-right: 5%;
                                margin-left: 2%;
                            }

                            .heading {
                                margin: 0;
                            }

                            .popup-body {
                                width: 30rem;

                                p {
                                    margin: 0;
                                    font-size: 1.3rem;
                                    color: $search-result-grey;
                                    line-height: 2rem;
                                }
                            }
                        }

                        .profile-popup-content {
                            display: none;
                            flex-direction: column;

                            .profile-popup {
                                display: flex;
                                margin-bottom: 4rem;

                                &:last-of-type {
                                    margin-bottom: 0;
                                }

                                h5 {
                                    margin: 0;
                                }

                                p {
                                    margin: 0;
                                    font-size: 1.3rem;
                                    color: $search-result-grey;
                                    line-height: 2rem;
                                }
                            }
                        }

                        .tablink {
                            width: 50%;
                            height: 5rem;
                            background-color: $search-button;
                            transition: $transition;
                            border: none;
                            font-weight: 700;
                            font-size: 1.6rem;
                            font-family: $ibm;
                            color: $white;

                            &.selected {
                                color: $dark-grey;
                                background-color: $white;

                                &:hover {
                                    cursor: default;
                                    background-color: $white;
                                }
                            }

                            &:hover {
                                cursor: pointer;
                                background-color: $accent1;
                            }

                            &.disabled {
                                background-color: $background-color-grey;
                                color: $not-clickable-grey;

                                &:hover {
                                    cursor: default;
                                    background-color: $background-color-grey;
                                }

                                &:focus {
                                    outline: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.Heading-Container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    .Header-Container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .right-container {

            .contact-message {
                justify-content: center;
                background-color: $accent3;

                h1,
                h2,
                h3,
                h4,
                h5,
                h6 {
                    display: flex;
                    justify-content: center;
                    padding: 1rem 2rem;
                }
            }

            .secondary-locations-container {
                display: flex;
                flex-direction: column;

                .second-location,
                .third-location {
                    background-color: $background-color-grey;
                    padding-left: 1rem;
                    padding-right: 1rem;

                    h1,
                    h2,
                    h3,
                    h4,
                    h5 {
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;
                        margin-bottom: 0;
                        margin-top: 4rem;
                    }

                    p,
                    li {
                        margin-top: 0;
                        margin-bottom: 4rem;
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;
                    }
                }

                .third-location {
                    margin-left: 0;
                    margin-top: 3%;
                }
            }
        }
    }

    .Title-Container {
        padding-top: 3rem;
        display: flex;
        align-items: center;

        hr {
            display: none;
        }

        i {
            font-size: 2.5rem;
            color: $accent1;

            &.fa-user {
                color: $accent3;
            }
        }

        h3 {
            padding-left: 1.5rem;
            width: 120%;
        }
    }

    .Locations-List-Container,
    .Public-Profiles-Container {
        display: flex;
        flex-direction: column;

        .Location-Container,
        .Profile-Container {
            padding-left: 1rem;
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $sidebar-grey;

            h5 {
                margin: 0;
            }

            p {
                margin: 0;
            }

            &:last-of-type {
                border-bottom: 0;
            }

            .Location-Name {
                .Name {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }

            .Street-Address {
                margin: 0;
                color: $search-result-grey;
            }

            .City-State {
                display: flex;

                .City {
                    margin: 0;
                    color: $search-result-grey;
                }

                .State {
                    margin: 0;
                    color: $search-result-grey;
                }

                .Zip {
                    margin: 0;
                    color: $search-result-grey;
                }
            }

            .Phone-Number {
                margin: 0;
                color: $search-result-grey;
            }
        }
    }
}


@media screen and (min-width:768px) {

    .gm-style {
        .gm-style-iw-a {
            .gm-style-iw-t {
                .gm-style-iw {
                    .gm-style-iw-d {
                        .location-popup-content,
                        .profile-popup-content {
                                min-width: 32rem !important;
                            }
                        }
                    }
                }
            }
        }

    .Heading-Container {
        .Header-Container {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .left-container {
                width: 49%;
            }

            .right-container {
                width: 49%;

                .secondary-locations-container {
                    flex-direction: row;
                    justify-content: space-between;

                    .second-location,
                    .third-location {
                        flex: 1;
                        max-width: 49%;
                    }

                    .third-location {
                        margin-top: 0;
                        margin-left: 3%;
                    }
                }
            }
        }

        .Title-Container {

            hr {
                width: 100%;
                color: $dark-grey;
                border-style: solid;
                display: block;
            }

            h3 {
                width: 40%;
            }
        }

        .Locations-List-Container,
        .Public-Profiles-Container {
            flex-direction: row;
            flex-wrap: wrap;

            .Location-Container,
            .Profile-Container {
                width: 33%;
                border-bottom: 0;
                padding-left: 0;
                padding-top: 0;
                padding-bottom: 1.5rem;
            }
        }
    }
}

@media screen and (min-width:1280px) {
    .Heading-Container {
        padding-left: 0;
        padding-right: 0;
    }
}
