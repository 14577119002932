﻿.inner-banner {
    background: $footer-grey-brown;
    padding: 3rem 0 1rem;

    .container {
        padding: 0 1.5rem;
    }

    

    h2 {
        margin-top: 0;
    }
}

ol.breadcrumb {
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;

    li {
        display: inline-block;
        color: $white;
        font-size: 1.4rem;

        &:after {
            content: "/";
            display: inline-block;
            margin: 0 1.5rem;
            color: $white;
        }

        &:last-of-type:after {
            display: none;
        }

        a {
            color: $white;
            text-decoration: none;
            font-size: 1.4rem;
        }
    }
}




@media only screen and (min-width : 1280px) {

    .inner-banner {
        .container {
            padding: 0;
        }
    }
}