@import 'variables';
@import 'colors';
@import 'typography';
@import 'lity';

@import 'accessibility';
@import 'buttons';

@import 'mixins';

@import 'header';
@import 'footer';

@import 'social';
@import 'forms';
@import 'three-column-layout';
@import 'four-column-layout';
@import 'background-layouts';
@import 'background-layout-form';
@import 'forty-sixty-layout';
@import 'homecard';

@import 'insetFiftyFifty';
@import 'insetFiftyFifty-imageLeft';
@import 'fulltwidth5050';
@import 'inline-bullet-list';
@import 'inlineWithForm';
@import 'featured-blog-post';
@import 'blog-listing';
@import 'paging';
@import 'dual-feature';

@import 'sidebar';
@import 'blog-detail';
@import 'categories';
@import 'search-results';
@import 'search-input';
@import 'registration-form';

@import 'inner-banner';
@import 'alert-banner';

@import 'map';

@import 'videobanner';

@import 'portal-tiles';
@import 'portal-nav';
@import 'portal-profile';


@import 'profile';

@import 'sitemap';

@import 'sfEditor';

:root {
    font-size: 62.5%;
}

body {
    margin-top: 200px;
    transition: all .5s;
    font-size: 16px;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.section {
    margin-top: 40px;
    margin-bottom: 80px;

    h1 {
        margin-bottom: 25px;
    }
}

.back-to-top-container {
    opacity: 0;
    right: 0;
    transition: all .5s;

    &.visible {
        opacity: 1;
    }

    z-index: 100;
    position: fixed;
    right: 10px;
    top: calc(100vh - 100px);
}

main {
    margin-top: 86px;
}

.container {
    max-width: 1270px;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
}

.constrainer {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.padded {
    padding: 5rem 1.5rem;
}

@media screen and (min-width: 768px) {

    .padded {
        padding: 15rem 0;
    }

    
}

@media screen and (min-width: 1080px) {

    main {
        margin-top: 126px;
    }
}

@media screen and (min-width: 1250px) {
    .constrainer {
        padding-left: 0;
        padding-right: 0;
    }
}
