﻿//styling to override sitefinity buttons
.sf-SubmitButton {
    font-family: $gotham;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: $white;
    padding: 1.3rem 1.5rem;
    background-color: $dark-grey;
    transition: 0.5s ease all;
    text-align: center;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    min-width: 13.1rem;
    font-family: $gotham;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: $white;
    border-width: 0px;

    &:hover {
        background-color: $red;
        cursor: pointer;
    }

    &:focus {
        background-color: $dark-grey;
        outline-color: $dark-grey;
        outline-style: double;
        outline-width: 3px;
    }

    &:disabled {
        background-color: $med-grey;
        opacity: .37;
    }
}

.button {
    a {
        font-family: $gotham;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        color: $white;
        padding: 1.3rem 1.5rem;
    }

    background-color: $dark-grey;
    transition: 0.5s ease all;
    text-align: center;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    min-width: 13.1rem;
    font-family: $gotham;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: $white;
    border-width: 0px;
    white-space: nowrap;

    &:hover {
        background-color: $red;
    }

    &:focus {
        background-color: $dark-grey;
        outline-color: $dark-grey;
        outline-style: double;
        outline-width: 3px;
    }

    &:disabled {
        background-color: $med-grey;
        opacity: .37;
    }

    &.small-button {
        padding-top: .7rem;
        padding-bottom: .7rem;
        min-width: 11.2rem;

        a {
            padding: .7rem 1.2rem;
        }
    }

    &.red-orange {
        background-color: $red-orange;

        &:hover {
            background-color: $red;
        }

        &:focus {
            outline-color: $red-orange;
            outline-style: double;
            outline-width: 3px;
        }

        &:disabled {
            background-color: $red-orange;
            opacity: .37;
        }
    }

    &.teal {
        background-color: $teal;

        &:hover {
            background-color: $dark-teal;
        }

        &:focus {
            outline-color: $dark-teal;
            outline-style: double;
            outline-width: 3px;
        }

        &:disabled {
            background-color: $light-teal;
            opacity: .37;
        }
    }
}

a.button:hover {
    color: $white;
}

.secondary-button {
    background-color: $white;
    transition: 0.5s ease all;
    text-align: center;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    min-width: 13.1rem;
    font-family: $gotham;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: $dark-grey;
    border-width: 0px;
    outline-width: 2px;
    outline-color: $dark-grey;
    outline-style: solid;
    white-space: nowrap;

    a {
        color: $dark-grey;
        font-family: $gotham;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        padding: 1.3rem 1.5rem;
    }

    &:hover {
        background-color: $red;
        outline-width: 2px;
        outline-color: $red;
        color: $white;

        a {
            outline-width: 2px;
            outline-color: $red;
            color: $white;
        }
    }

    &:focus {
        color: $white;
        background-color: $dark-grey;
        outline-color: $dark-grey;
        outline-style: double;
        outline-width: 3px;
    }

    &:disabled {
        opacity: .37;
        outline-color: $med-grey;
        color: $med-grey;
        background-color: $white;
        outline-width: 2px;
    }

    &.small-button {
        padding-top: .7rem;
        padding-bottom: .7rem;
        min-width: 11.2rem;

        a {
            padding: .7rem 1.2rem;
        }
    }

    &.red-orange {
        outline-color: $red-orange;
        color: $red-orange;

        &:hover {
            background-color: $red-orange;
            color: $white;
        }

        &:focus {
            color: $white;
            background-color: $red-orange;
            outline-color: $red-orange;
            outline-style: double;
            outline-width: 3px;
        }

        &:disabled {
            opacity: .37;
            color: $red-orange;
            background-color: $white;
            outline-width: 2px;
        }
    }

    &.transparent {
        background-color: transparent;
    }

    &.white {
        outline-color: $white;
        color: $white;

        a {
            color: $white;
            padding: 1.3rem 2.5rem;
        }

        &:hover {
            background: darken($accent1, 20%);
        }
    }
}

.ghost-button {
    background-color: transparent;
    transition: 0.5s ease all;
    text-align: center;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    min-width: 13.1rem;
    font-family: $gotham;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    color: $dark-grey;
    border-width: 0px;

    a {
        font-family: $gotham;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        color: $dark-grey;
        padding: 1.3rem 1.5rem;

        i {
            padding-left: 1%;
        }
    }

    &:hover {
        color: $white;
        background-color: $red;

        a {
            color: $white;
        }
    }

    &:focus {
        background-color: $dark-grey;
        outline-color: $dark-grey;
        outline-style: double;
        outline-width: 3px;
        color: $white;
    }

    &:disabled {
        opacity: .37;
        color: $med-grey;
        background-color: $white;
    }

    &.small-button {
        padding-top: .7rem;
        padding-bottom: .7rem;
        min-width: 11.2rem;

        a {
            padding: .7rem 1.2rem;
        }

        &:hover {
            color: $white;
            background-color: $dark-grey;
        }
    }

    &.red-orange {
        color: $red-orange;

        &:hover {
            color: $white;
            background-color: $red-orange;
        }

        &:focus {
            background-color: $red-orange;
            outline-color: $red-orange;
            outline-style: double;
            outline-width: 3px;
            color: $white;
        }

        &:disabled {
            background-color: $white;
            color: $red-orange;
        }
    }

    &.teal {
        background-color: $white;
        transition: 0.5s ease all;
        text-align: center;
        padding: 1.3rem 1.5rem;
        min-width: 13.1rem;
        font-family: $ibm;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        color: $teal;
        border-width: 0px;

        &:hover {
            color: $white;
            background-color: $teal;
        }

        &:focus {
            background-color: $teal;
            outline-color: $teal;
            outline-style: double;
            outline-width: 3px;
            color: $white;
        }

        &:disabled {
            background-color: $white;
            color: $teal;
        }

        &.small-button {
            padding: .7rem 1.2rem;
            min-width: 11.2rem;
        }
    }
}