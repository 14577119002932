﻿.content-form-container {
    display: flex;
    flex-direction: column;

    .blocked-content-container {
        background: $white;
        padding: 2.5rem 1.5rem;
        box-sizing: border-box;
        margin-right: 5%;

        form {
            display: flex;
            flex-direction: column;
            .button {
                margin-top: 5%;
                padding-top: 0;
                padding-bottom: 0;
                a {
                    display: block;
                }
            }
            .input-wrapper {
                max-width: 100%;
            }
        }
    }

}

@media screen and (min-width: 768px) {

    .content-form-container {
        flex-direction: row;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        .blocked-content-container {
            width: 50%;
            background: $white;
            padding: 5rem 3rem;
            box-shadow: 0px 3px 36px rgba(0,0,0,.1);

            &.right {
                margin-left: 5%;
                margin-right: 0;
            }
        }

        .content-area {
            max-width: 50%;
        }
    }
}

@media screen and (min-width: 1288px) {
    .content-form-container {
        padding-left: 0;
        padding-right: 0;
    }
}