﻿
.profile {

    .profile-banner {
        background: $accent3;

        .container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .profile-photo {
                order: 2;
                margin: 2.5rem;
                width: 100%;
                display: flex;
                justify-content: center;

                img {
                    width: 200px;
                    border-radius: 50%;
                    box-shadow: 0 0.6rem 1.5rem rgba(0,0,0,.34);
                    height: 200px;
                }
            }

            .profile-info {
                order: 4;


                h1 {
                    color: $white;
                    padding: 0 2.5rem;
                    margin-top: 2.5rem;
                }

                .job-title {
                    color: $white;
                    padding: 0 2.5rem;
                    font-size: 2.4rem;
                }
            }


            .profile-detail {
                order: 3;
                padding: 2.5rem;
                color: $white;
                line-height: 2.8rem;

                a {
                    color: $white;
                }

                i {
                    color: $white;
                    display: block;
                    font-size: 2.8rem;
                    margin: 1.5rem 0;
                }

                > div {
                    padding: 1.5rem 0;
                }
            }
        }
    }
}

.profile-story {
    padding: 2.5rem;
}


@media screen and (min-width: 768px) {
    .background-profile {
        width: 100%;
    }

    .profile {
        .profile-banner {
            .container {
                justify-content: flex-start;

                .profile-photo {
                    order: 1;
                    max-width: 32rem;
                    align-items:center;
                    margin-top:4rem;


                    img {
                        max-width: 100%;
                        max-height: 250px;
                    }
                }

                .profile-info {
                    order: 2;
                    padding: 0 2.5rem;
                    display: flex;
                    width: calc( 100% - 42rem );
                    flex-direction: column;
                    box-sizing: border-box;


                    h1,
                    .job-title {
                        padding-left:0;
                        display: block;
                        margin-bottom: 1rem;
                    }
                }

                .profile-detail {
                    order: 3;
                    padding: 0;
                    display: flex;
                    flex-wrap: wrap;

                    i {
                    }

                    > div {
                        padding: 1.5rem 0;
                        width: 30%;
                    }
                }
            }
        }
    }

    .profile-story {
        padding: 2.5rem;
    }
}
