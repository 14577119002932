﻿.fiftyfifty-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .content-container {
        .constrainer {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }
    }

    .image-container {
        min-height: 300px;
        width: 100%;

        .background-setter {
            height: 100%;
        }
    }

    &.image-right {
        flex-wrap: wrap-reverse;
    }
}


@media screen and (min-width: 768px) {
    .fiftyfifty-container {
        flex-direction: row;

        .content-container {
            width: 46%;
            justify-content: flex-start;
            margin: 0 2%;

            .constrainer {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 5rem 2.5rem 5rem 5rem;

                &.no-padding {
                    padding: 0;
                }
            }
        }

        &.image-right {
            flex-direction: row;
            justify-content: flex-end;

            .constrainer {
                padding: 5rem 5rem 5rem 2.5rem;
            }
        }

        .image-container {
            width: 50%;
        }
    }
}

@media screen and (min-width: 1280px) {
    .fiftyfifty-container {
        flex-wrap: nowrap;
        .content-container {
            justify-content: flex-start;
            width: 640px;

            .constrainer {
                width: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 7.5rem 0 7.5rem 5rem;
            }
        }

        &.image-right {
            flex-wrap: nowrap;
            .content-container {
                justify-content: flex-end;

                .constrainer {
                    padding: 7.5rem 5rem 7.5rem 0;
                }
            }
        }
    }
}
