﻿::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $placeholer-text;
}

::-moz-placeholder { /* Firefox 19+ */
    color: $placeholer-text;
}

:-ms-input-placeholder { /* IE 10+ */
    color: $placeholer-text;
}

:-moz-placeholder { /* Firefox 18- */
    color: $placeholer-text;
}

input[type="search"] {
    -webkit-appearance: none;
    border-radius: 0;
}

form {
    box-sizing: border-box;

    .input-wrapper,
    .form-group, 
    .sf-fieldWrp {
        border: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-block-start: 0;
        padding-inline-start: 0;
        padding-inline-end: 0;
        padding-block-end: 0;

        label,
        legend {
            font-family: $ibm;
            font-size: 1.4rem;
            color: $form-label;
            display: block;
        }

        input[type=text],
        input[type=number],
        input[type=email],
        input[type=tel],
        input[type=password],
        select,
        textarea {
            border: 0;
            background-color: $form-field-bg;
            padding: 1.5rem;
            width: calc(100% - 3rem);
            margin-bottom: 3%;
            font-size: 1.4rem;
        }

        select {
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            ///resourcePackages/Bootstrap4/assets/dist/
            ///img/Shape.svg
            background: url(/resourcePackages/Bootstrap4/assets/dist/img/Shape.svg) 96% / 4% no-repeat #eee;
            background-color: $form-field-bg;
        }

        &.error {
            > div {
                border-top: 2px solid $accent1;
                font-family: $ibm;
                font-size: 1.4rem;
                color: $form-label;
                width: 100%;
                padding-top: 1.5rem;


                span {
                    color: $accent1;
                    display: inline-block;
                    padding-right: 0.5rem;
                }
            }
        }

        input[type=checkbox] {
            position: absolute;
            opacity: 0;

            & + label {
                position: relative;
                cursor: pointer;
                padding: 0;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            & + label:before {
                content: '';
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                width: 20px;
                height: 20px;
                background: $white;
                box-shadow: 0 0 0 3px $dark-accent3 inset;
                transition: $transition;
            }

            & + label:after {
                transform: rotate(45deg);
                transition: $transition;
            }

            &:hover + label:before {
                background: $light-teal;
            }

            &:focus + label:before {
                background: $light-teal;
                box-shadow: 0 0 0 3px $dark-accent3 inset;
            }

            &:checked + label:before {
                background: $light-teal;
                box-shadow: none;
            }

            &:disabled + label {
                color: #b8b8b8;
                cursor: auto;
            }

            &:disabled + label:before {
                box-shadow: none;
                background: #ddd;
            }

            &:checked + label:after {
                content: '';
                position: absolute;
                left: 5px;
                top: 9px;
                background: $white;
                width: 2px;
                height: 2px;
                box-shadow: 2px 0 0 $white, 4px 0 0 $white, 4px -2px 0 $white, 4px -4px 0 $white, 4px -6px 0 $white, 4px -8px 0 $white;
            }
        }

        ul {
            margin-block-start: 0;
            margin-block-end: 0;
            padding-inline-start: 0;
            list-style-type: none;

            li {
                list-style-type: none;

                label {
                    position: relative;
                    cursor: pointer;
                    padding: 0;
                    margin: 10px;

                    input[name=MultipleChoiceFieldController] {
                        content: '';
                        margin-right: 10px;
                        display: inline-block;
                        vertical-align: text-top;
                        width: 18px;
                        height: 18px;
                        background: $white;
                        box-shadow: 0 0 0 3px $dark-accent3 inset;
                        border-radius: 50%;
                        transition: $transition;
                        -webkit-appearance: none;
                        cursor: pointer;
                        margin-top: 0;
                        position: relative !important;
                        opacity: initial !important;

                        &:hover {
                            background-color: $light-teal;
                        }

                        &:focus {
                            background: $light-teal;
                            box-shadow: 0 0 0 3px $dark-accent3 inset;
                        }

                        &.checked {
                            background: $white;
                            box-shadow: none;
                            box-shadow: 0 0 0 3px $light-teal inset;
                        }

                        &.checked:after {
                            content: '';
                            position: absolute;
                            background: $light-teal;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            left: 5px;
                            top: 5px;
                        }
                    }
                }
            }
        }

        input[type=radio] {
            position: absolute;
            opacity: 0;

            & + label {
                position: relative;
                cursor: pointer;
                padding: 0;
                margin: 10px;
            }

            & + label:before {
                content: '';
                margin-right: 10px;
                display: inline-block;
                vertical-align: text-top;
                width: 20px;
                height: 20px;
                background: $white;
                box-shadow: 0 0 0 3px $dark-accent3 inset;
                border-radius: 50%;
                transition: $transition;
            }

            & + label:after {
                transition: $transition;
            }

            &:hover + label:before {
                background: $light-teal;
            }

            &:focus + label:before {
                background: $light-teal;
                box-shadow: 0 0 0 3px $dark-accent3 inset;
            }

            &:checked + label:before {
                background: $white;
                box-shadow: none;
                box-shadow: 0 0 0 3px $light-teal inset;
            }

            &:disabled + label {
                color: #b8b8b8;
                cursor: auto;
            }

            &:disabled + label:before {
                box-shadow: none;
                background: #ddd;
            }

            &:checked + label:after {
                content: '';
                position: absolute;
                background: $light-teal;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                left: 6px;
                top: 6px;
            }
        }
    }
}

//This is the only additional class needed to be added to a base highland form in order for it to pick up this styling
.inline-with-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .mainDiv {
        width: 40%;
    }

    input[type=text] {
        border: 0;
        background-color: $form-field-bg;
        padding: 1.5rem;
        width: calc(100% - 3rem);
        margin-bottom: 3%;
    }

    input[type=button] {
        background-color: $teal !important;
        transition: 0.5s ease all;
        text-align: center;
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;
        width: 100%;
        font-family: $gotham !important;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        color: $white;
        border-width: 0px;

        &:hover {
            background-color: $dark-teal !important;
        }

        &:focus {
            outline-color: $dark-teal;
            outline-style: double;
            outline-width: 3px;
        }
    }

    .emptyCell {
        height: 0;
    }

    .maxSize1 {
        font-family: $ibm !important;
        font-size: 1.4rem !important;
        color: $form-label !important;
        display: block !important;
    }

    .clear {
        display: none;
    }

    h1,
    h2,
    h3,
    h4 {
        text-align: center;
    }

    .button {
        width: calc(100% - 3rem);
        max-width: 27.5rem;
    }
}