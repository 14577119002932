﻿.results-50-50 {
    flex-direction: column;
    flex-wrap: nowrap;

    .search-results {
        display: flex;
        align-items: center;
        padding-bottom: 3rem;

        .form-group {
            width: 100%;

            input[type="search"] {
                height: 4rem;
                width: 50%;
                background-color: rgba(61,112,178,.1);
                border: none;
                padding-left: 2rem;
                margin-right: 1rem;
                font: 400 1.4rem $ibm;
                color: $placeholer-text;
            }

            button {
                background-color: $white;
                transition: $transition;
                text-align: center;
                padding-top: .9rem;
                padding-bottom: .9rem;
                min-width: 13.1rem;
                font-family: $gotham;
                font-size: 1.4rem;
                font-weight: bold;
                text-decoration: none;
                color: $dark-grey;
                border-width: 0px;
                outline-width: 2px;
                outline-color: $dark-grey;
                outline-style: solid;

                &:hover {
                    background-color: $red;
                    outline-width: 2px;
                    outline-color: $red;
                    color: $white;
                    cursor: pointer;
                }

                &:focus {
                    color: $white;
                    background-color: $dark-accent1;
                    outline-color: $dark-accent1;
                    outline-style: double;
                    outline-width: 3px;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .results-50-50 {
        flex-direction: row;

        .search-results {
            padding-top: 3rem;

            .form-group {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}