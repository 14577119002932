header {
    nav {
        .logo {
            img {
                
            }
        }

        
    }
}




header {
    flex-direction: row;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 50;
    background-color: $white;


    nav {
        flex-wrap: nowrap;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            max-width: 40%;
            padding-left: 1.5rem;

            img {
                width: 100%;
                margin-top: 0.5rem;
                max-width: 52px;
            }

            .desktop {
                display: none;
            }

            .mobile {
                display: block;
            }
        }

        ul.navbar {
            display: none;
        }


        .loginPanel > div > div {
            display: flex;
            padding: 1rem .5rem;

            .secondary-button {
                margin-top: 0.2rem;
                margin-bottom: 0.2rem;
            }

            .nav-button {
                min-width: 8.2rem;
            }

            .right-margin {
                margin-right: 1rem;
            }
        }

        .mobile-toggle {
            display: flex;
            justify-content: flex-end;
            margin-right: 1rem;


            a {
                justify-content: center;
            }

            p {
                display: none;
            }
        }


        #nav-icon {
            width: 2.5rem;
            height: 1.5rem;
            position: relative;
            margin: .8rem;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;
            cursor: pointer;
            display: inline-block;


            span {
                display: block;
                position: absolute;
                height: .3rem;
                width: 100%;
                background: $dark-grey;
                border-radius: .9rem;
                opacity: 1;
                left: 0;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: .25s ease-in-out;
                -moz-transition: .25s ease-in-out;
                -o-transition: .25s ease-in-out;
                transition: .25s ease-in-out;
            }


            span:nth-child(1) {
                top: 0;
            }

            span:nth-child(2), span:nth-child(3) {
                top: .6rem;
            }

            span:nth-child(4) {
                top: 1.2rem;
            }

            &.open span:nth-child(1) {
                top: 1.8rem;
                width: 0%;
                left: 50%;
            }

            &.open span:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            &.open span:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }

            &.open span:nth-child(4) {
                top: 1.8rem;
                width: 0%;
                left: 50%;
            }

            p {
                padding-left: 4rem;
            }
        }
    }
}

.top-hat {
    background: $top-hat;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0 2.5rem;
    box-sizing: border-box;
    transition: $transition;

    .header-container {
        display: flex;
        justify-content: space-between;

        .phone-social-container {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .phone {
                p,
                a,
                div {
                    color: $white;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    height: 100%;
                    margin-block-start: 0;
                    margin-block-end: 0;
                    font-size: 1.4rem;
                }
            }

            .social {
                display: flex;
                align-items: center;

                ul {
                    margin-bottom: 0;
                    margin-block-start: 0;
                    margin-block-end: 0;
                    margin-top: 0;
                }

                li {
                    display: inline;
                    height: 100%;

                    a {
                        color: $white;
                        text-decoration: none;
                        transition: $transition;

                        i {
                            padding: 1.3rem 1rem;
                            transition: $transition;
                        }

                        &:hover {
                            text-decoration: none !important;
                        }
                    }
                }
            }
        }
    }
}


.mobile-menu {
    position: absolute;
    height: calc(100vh - 8.5rem);
    overflow-y: auto;
    transition: $transition;
    z-index: -1;
    background: $white;
    width: calc(100% - 40vw);
    visibility: hidden;
    opacity: 0;
    left: 40vw;
    top: 5vh;

    &.open {
        left: 0;
        z-index: 500;
        opacity: 1;
        visibility: visible;
        width: 100vw;
        background-clip: content-box;
        overflow-y: auto;
        top: 7rem;
    }

    .search-container {
        border-top: 1px solid $search-button;
        width: 80%;
        margin-left: 10%;
        margin-top: 5rem;
        padding: 3rem 0;
        display: flex
    }

    .search-input {
        width: 100%;

        input {
            width: 100%;
            display: inline-flex;
            background: $search-button;
            height: 3.45rem;
            border: none;
            color: $white;
            box-sizing: initial;
            margin-left: 0;
            border-radius: 0;
        }
    }

    .activate-search {
        min-width: 10.7rem;

        a {
            background-color: $search-button;
            padding: 1rem 1.5rem;
            display: inline-flex;
            color: $white;
            text-decoration: none;

            &:hover {
                background-color: $red;
                color: $white !important;
                text-decoration: none !important;
            }
        }
    }

    .activate-search a span {
        margin-left: 1rem;
    }

    .search-button {
        button {
            display: none;
        }
    }
}

body.open {
    overflow-y: hidden;
}

ul.navbar {
    list-style: none;
    position: relative;
    padding-inline-start: 2.5rem;
    width: 89%;

    ul {
        list-style: none;
    }

    a {
        color: $black;
        font-family: $gotham;
        font-weight: 500;
        font-size: 2.1rem;
        padding: 1rem;
        display: block;
        margin: 1rem 2.0rem;
        box-sizing: content-box;
        border-radius: 0.5rem;
        transition: all .5s;
        text-decoration: none;
        white-space: nowrap;
    }

    .hasDropdown {
        transition: $transition;
        position: relative;

        ul {
            visibility: hidden;
            height: 0;
            transition: $transition;
            overflow: hidden;
            opacity: 0;
            padding-inline-start: 2rem;

            a {
                font-size: 1.7rem;
            }
        }
    }

    .hasDropdown:after {
        font-family: $font-awesome;
        content: "\f078";
        display: inline-block;
        position: absolute;
        right: 1.5rem;
        top: 1.5rem;
        font-weight: 900;
        transition: $transition;
    }

    .hasDropdown {
        &.open {
            color: $accent1;
            border-bottom: .3rem solid $accent1;

            > a {
                color: $accent1;
            }

            &:after {
                transform: rotate(180deg);
            }

            ul {
                visibility: visible;
                height: auto;
                opacity: 1;
            }
        }
    }
}


@media screen and (min-width: 450px) {
    .mobile-menu {
        .search-input {
            input {
                height: 3.8rem;
            }
        }
    }
    header {
        nav {
            .logo {
                img {
                    margin-top: 0rem;
                    max-width: none;
                }
            }

            .loginPanel div {
                .nav-button {
                    min-width: 8.2rem;
                }
            }

            .mobile-toggle {
                min-width: 10.4rem;
                margin-right: 1.5rem;

                a {
                    border: 1px solid $dark-grey;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    text-decoration: none;
                    padding: 0.5rem;

                    p {
                        display: block;
                        line-height: 1.5rem;
                        font-weight: 700;
                        font-family: $gotham;
                        margin-bottom: 0;
                        margin-block-start: 0em;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1080px) {

    header {
        background-color: $header-background;

        .top-hat {
            background: $top-hat;
            position: relative;
            bottom: auto;
            width: auto;
            padding: 0;
            box-sizing: border-box;
            transition: $transition;

            .header-container {
                padding: 0 5rem;
                display: flex;
                justify-content: space-between;

                .phone-social-container {
                    display: flex;
                    justify-content: flex-start;

                    .phone {
                        p,
                        a,
                        div {
                            color: $white;
                            text-decoration: none;
                            display: flex;
                            align-items: center;
                            height: 100%;
                            margin-block-start: 0;
                            margin-block-end: 0;
                        }
                    }

                    .social {
                        display: flex;
                        align-items: center;

                        ul {
                            margin-bottom: 0;
                            margin-block-start: 0;
                            margin-block-end: 0;
                        }

                        li {
                            display: inline;
                            height: 100%;


                            a {
                                color: $white;
                                text-decoration: none;

                                i {
                                }

                                &:hover {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }

            .search-container {
                display: flex;

                .search-input {
                    width: 0;
                    overflow: hidden;
                    transition: $transition;

                    input {
                        height: 4.2rem;
                        background: $search-button;
                        border: none;
                        color: $white;

                        &:focus {
                            outline: none;
                        }
                    }

                    &.open {
                        width: 16.6rem;
                    }
                }

                .activate-search {
                    width: 5.0rem;
                    height: 100%;

                    a {
                        text-decoration: none;

                        span {
                            visibility: hidden;
                            height: 0;
                            width: 0;
                            position: absolute;
                            left: -99999px;
                        }
                    }

                    .search-icon {
                        background-color: $search-button;
                        color: $white;
                        width: 100%;
                        height: calc(100% - 1rem);
                        display: block;
                        font-size: 2rem;
                        text-decoration: none;
                        font-weight: 600;
                        text-align: center;
                        padding-top: 1rem;
                    }

                    button {
                        &.search-icon {
                            height: 100%;
                            padding-top: 0.75rem;
                            padding-bottom: 0.75rem;
                            -webkit-appearance: listitem;
                        }
                    }
                }

                .search-button {
                    button {
                        display: none;
                    }
                }
            }
        }

        nav {
            padding: 0 5rem;
            height: 8.6rem;

            .logo {
                max-width: 22.1rem;
                padding-left: 0;

                .desktop {
                    display: block;
                }

                .mobile {
                    display: none;
                }
            }

            ul.navbar {
                display: flex;
                justify-content: space-evenly;
                margin-block-start: 0;
                margin-block-end: 0;
                width: 60%;
                height: 100%;
                align-items: center;

                li {
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    transition: $transition;

                    a {
                        padding: 3rem 0.5rem;
                        margin: 1rem 0.5rem;
                        font-weight: 300;
                        font-size: 1.5rem;
                        position: relative;
                        text-decoration: none;

                        &:hover,
                        &:focus {
                            background-color: transparent;
                        }
                    }

                    &:hover,
                    &:focus {
                        background-color: $accent1;

                        > a {
                            color: $white;
                            text-decoration: none;
                        }
                    }
                }

                .hasDropdown {

                    ul {
                        background: $white;
                        top: 8.6rem;
                        padding-inline-start: 0;
                        min-width: calc( 100% - 0.2rem);
                        width: auto;
                        left: 0;
                        position: absolute;
                        padding-left:0;
                    }

                    &.open {
                        position: relative;
                        background-color: $accent1;

                        > a {
                            color: $white;
                        }

                        ul {
                            z-index: 501;
                            border: 1px solid $dark-accent3;

                            li {
                                border-bottom: 1px solid $dark-accent3;

                                a {
                                    padding: 0.5rem 0.5rem;
                                }

                                &:last-of-type {
                                    border-bottom: none;
                                }
                            }
                        }
                    }
                }

                .hasDropdown:after {
                    display: none;

                    &:hover {
                        display: inline;
                    }
                }

                .hasDropdown:hover > a:after,
                .hasDropdown.open > a:after {
                    font-family: $font-awesome;
                    content: "\f0d7";
                    display: block;
                    height: 15px;
                    width: 15px;
                    position: absolute;
                    margin-left: calc(50% - 15px);
                    padding-top: 10px;
                    font-weight: 600;
                }
            }

            .loginPanel div {
                padding-right: 0;

                .nav-button {
                    display: inline-block;
                    margin-right: 1.5rem;
                    padding: 1.3rem 1.5rem;
                }
            }


            .mobile-toggle {
                display: none;
            }
        }

        &.shrink {


            nav {
                height: 6.6rem;

                .logo {
                    img {
                        max-height: 4rem;
                        width: auto;
                    }
                }
            }
        }
    }

    .mobile-menu {
        display: none;
    }
}

@media screen and (min-width: 1280px) {
    header {
        nav {
            ul.navbar {
                justify-content: flex-start;
                width: 69%;

                li {
                    padding: 0 0.5rem;

                    a {
                        padding: 3rem 1rem;
                        margin: 1rem 2rem;
                    }
                }
            }
        }
    }
}

@media print {
    header {
        position: relative;

        .top-hat {
            position: unset;
            padding: 0 1.5rem;

            .header-container {
                .phone {
                    padding-bottom: 1.5rem;
                }

                .social {
                    display: none !important;
                }

                .search-container {
                    display: none !important;
                }
            }
        }

        nav {
            .loginPanel {
                display: none !important;
            }

            .mobile-toggle {
                display: none !important;
            }
        }
    }
}
