﻿.paging-container {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    border: 1px solid $sidebar-grey;
    justify-content: flex-end;

    .page-counter {
        font-family: $ibm;
        color: $placeholer-text;
        font-size: 1.3rem;
        margin-right: 12%;
    }


    .not-clickable {
        padding: 1.6rem 2rem;
        border-left: 1px solid $sidebar-grey;
        color: $not-clickable-grey;
    }

    .page-link {
        padding: 1.6rem 2rem;
        border-left: 1px solid $sidebar-grey;
    }

    .previous-page-container {

        .page-link {
            border-right: 1px solid $sidebar-grey;
        }

        .not-clickable {
            border-right: 1px solid $sidebar-grey;
        }
    }

    form {
        .input-wrapper {
            width: 7.4rem;

            select {
                margin-bottom: 0;
                font: 400 1.4rem $ibm;
                color: $light-teal;
                background-size: auto;
                background-position-x: 60%;
                background-color: white;
            }
        }
    }
}


@media screen and (min-width: 768px) {
    .paging-container {
        .page-counter {
            margin-right: 2%;
        }

            .not-clickable,
            .page-link {
                padding-left: 1.6rem 2rem;
            }

        form {
            .input-wrapper {
                width: 6rem;
            }
        }
    }
}
