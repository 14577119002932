﻿
.videobanner {
    width: 100%;
    position: relative;
    background-size: cover!important;

    .content-wrapper {
        position: relative;
        padding: 5rem 5%;
    }

    .video {
        display: none;
    }
}

@media screen and (min-width: 768px) {

    .videobanner {
        width: 100%;
        position: relative;
        background:none!important;



        .content-wrapper {
            position: absolute;
            padding: 0;
            top: 0;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            justify-items: center;
            height: 70%;
            max-width: 127rem;
            width:100%;

            .content {
               
                align-self: center;
                padding: 10rem 1.5rem;
                height:40%;
            }

            
        }

        .video {
            display: block;
        }
        .video {
            overflow:hidden;
        }
        video {
            width: auto;
            height: auto;
        }
    }
}

@media screen and (min-width:1280px) {
    .videobanner {
        .content-wrapper {
            .content {
                padding: 10rem 0;
                max-width:50%;
                height:60%;
            }
        }

        video {
            width: 100%;
        }
    }
}
