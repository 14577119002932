﻿.search-results-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .results-text-container {

        p {
            font-family: $ibm;
            color: $search-result-grey;
            font-size: 1.6rem;

            em {
                font-style: normal;
                font-weight: bold;
                color: $black;
            }
        }
    }

    .search-results-list-container {
        .result-container {
            border-bottom: 1px solid $sidebar-grey;
            padding-bottom: 2rem;
            display: flex;
            align-items: center;

            .media-left {
                max-height: 120px;
                overflow: hidden;
                min-width: 120px;
                margin-right: 3rem;
            }

            i {
                margin-right: 3rem;
                font-size: 8rem;

                &.fa-file-pdf {
                    color: $PDF;
                }

                &.fa-file-word {
                    color: $word-doc;
                }

                &.fa-file-powerpoint {
                    color: $powerpoint;
                }

                &.fa-file-excel {
                    color: $excel;
                }

                &.fa-rss {
                    color: $highland-light-teal;
                }

                &.fa-file-alt {
                    color: $accent5;
                }
            }

            i,
            img {
                width: 120px;
            }

            &:last-of-type {
                border-bottom: none;
            }
        }
    }
}

@media screen and (min-width: 1300px) { 
    .search-results-container {
        padding: 0;
    }

}
