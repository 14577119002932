﻿.withSidebar {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    .main {
        width: 100%;
    }

    aside {
        width: 100%;
        margin: 3rem;

        > div {
            border-bottom: 0.1rem solid $sidebar-grey;
            padding: 3rem;

            &:first-of-type {
                padding-top: 0;

                h3 {
                    margin-block-start: 0;
                }
            }
        }
    }
}



@media screen and (min-width: 1080px) {
    .withSidebar {
        flex-wrap: nowrap;
        margin-bottom: 5rem;

        .main {
            width: 65%;
            padding-right: 3rem;
        }

        aside {
            width: 34%;
            margin: 5rem 0 0 0;
            border-left: 0.1rem solid $sidebar-grey;

            > div {
                border-bottom: 0.1rem solid $sidebar-grey;
            }
        }
    }
}
