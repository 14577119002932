﻿.dual-feature-container {
    margin-bottom: 5%;

    .fiftyfifty-container {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: nowrap;
        padding: 1.5rem;

        .content-container {
            margin-top: 5%;

            img {
                width: 100%;
            }

            .fiftyfifty-container {
                padding-left: 0;
                padding-right: 1.5rem;
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;

                div:first-of-type {
                    border-bottom: 0;
                }

                .content-container {
                    margin-top: 0;
                    border-bottom: 1px solid $dark-accent4;
                    padding-bottom: 2rem;

                    .constrainer {
                        padding-left: 0;
                    }

                    p {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .dual-feature-container {
        .fiftyfifty-container {
            display: flex;
            flex-direction: row;

            div:first-of-type {
                margin-right: 2.5%;
            }

            .content-container {
                margin-top: 0;

                .fiftyfifty-container {
                    margin: 0;
                    padding-left: 0;

                    .content-container {
                        border-left: 1px solid $dark-accent4;
                        border-bottom: 0;
                        padding-left: 2rem;

                        .constrainer {
                            padding-left: 2rem;
                        }

                        p {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}