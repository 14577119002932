﻿.inline-bullet-list-container {
    display: flex;
    flex-direction: column;

    .content {
        display: flex;

        justify-content: center;
    }
}

@media screen and (min-width: 768px) {
    .inline-bullet-list-container {
        display: flex;
        flex-direction: row;

        div {
            margin-right: 25%;
        }
        div:last-of-type {
            margin-right: 0;
        }
    }
}