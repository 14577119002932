﻿.blog-detail {
    padding: 1.5rem;
    margin-bottom: 3rem;

    h1 {
        margin-top: 3rem;
    }

    .featured-image {
        img {
            width: 100%;
        }
    }

    .meta {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 1.5rem 0;
        font-size: 1.4rem;
        line-height: 2.5rem;

        .date {
            margin-right: 3rem;
        }

        .author {
            margin-right: 3rem;
        }

        span {
            margin-right: 0.3rem;
        }

        a {
            font-weight: 700;
            color: $dark-accent1;

            &:hover {
                color: $accent1;
            }

            &:after {
                content: ', ';
                display: inline-block;
            }

            &:last-of-type {
                &:after {
                    display: none;
                }
            }
        }
    }

    .blog-content {
        padding-bottom: 1.5rem;
    }

    .sf-Comments {
        .sf-Comments-list {
            .sf-media {
                .sf-img-thmb {
                    display: none;
                }
                .sf-media-body {
                    padding: 1.5rem 0;

                    .text-muted {
                        font-weight: 600;
                    }
                }
            }
        }

        .sf-Comments-form {
            .sf-media {
                .sf-img-thmb {
                    display: none;
                }

                .sf-media-body {
                    .form-group {
                        .form-control {
                            width: 75%;
                            height: 100px;
                            font-family: $ibm;
                            color: $dark-grey;
                            font-weight: 200;
                            font-size: 1.6rem;
                            line-height: 2.4rem;
                        }

                        .text-danger {
                            padding-top: 1.5rem;
                            padding-bottom: 1.5rem;
                            color: $accent1;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (min-width:768px) {

    .blog-detail {
        padding: 3rem;
        margin-bottom: 5rem;

        .meta {
            flex-wrap:nowrap;
            justify-content:flex-start;
            margin: 1rem 0 2rem;
        }

        .featured-image {
            margin-bottom:6rem;
        }
    }

}

@media screen and (min-width:1280px) {

    .blog-detail {
        padding: 0rem;
        margin-bottom: 3rem;
    }
}

@media print {

    img {
        max-width: 60rem !important;
    }

    aside {
        display: none;
    }
    footer {
        display: none;
    }

    .ghost-button {
        display: none;
    }
}