﻿.inset-fiftyfifty-container {
    display: flex;
    flex-direction: column-reverse;

    .content-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .inset-image {
            img {
                width: 100%;
            }
    }
}

@media screen and (min-width: 768px) { 
    .inset-fiftyfifty-container {
        flex-direction: row;
        justify-content: space-between;

        .content-container {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 2.5%;
        }
        .inset-image {
            width: 50%;
            margin-left: 2.5%;
        }
    }
}

@media screen and (min-width: 1288px) { 
    .inset-fiftyfifty-container {
        .content-container {
            padding-left: 0;
            padding-right: 0;
        }
    }
}