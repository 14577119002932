﻿.card-container {
    display: flex;
    flex-wrap: wrap;
    padding: 25px 0;

    a {
        width: 100%;

        img {
            max-width: 42px;
        }

        .card {
            display: flex;
            justify-content: center;
            align-items: center;

            .card-body {
                padding-left: 15px;
                width: 30%;

                .card-title {
                    transition: $transition;
                }

                .card-text {
                    display: none;
                }
            }
        }
    }
}


@media screen and (min-width:768px) {
    .card-container {
        display: flex;
        flex-wrap: wrap;

        a {
            width: 50%;

            &:hover {
                text-decoration: none;

                .card-title {
                    color: $accent1;
                    text-decoration: underline;
                    margin: 10px 0 0;
                }

                .card-text {
                    text-decoration: none;
                }
            }

            img {
                padding-left: 15px;
            }

            .card {
                align-items: flex-start;
                flex-direction: column;
                padding-bottom: 30px;
                padding-top: 10px;

                .card-body {

                    .card-title {
                        margin: 10px 0 0;
                    }

                    .card-text {
                        display: block;
                        text-decoration: none !important;
                    }
                }
            }
        }
    }
}