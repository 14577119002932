﻿.forty-sixty-container {
    box-sizing: border-box;

    .container {
        display: flex;
        flex-direction: column;

        > .content-container:first-of-type {
            background-color: $accent3;
            color: $white;
            padding: 25px 15px;
        }

        > .content-container:last-of-type {
            background-color: $home-grey-accent;
        }
    }

    .fiftyfifty-container {
        .content-container {
            width: 100%;
            padding: 1rem 2.5rem;
        }
    }
}


@media screen and (min-width: 768px) {
    .forty-sixty-container {
        flex-direction: row;
        background: $accent3; /* Old browsers */
        background: -moz-linear-gradient(left, $accent3 0%, $accent3 50%, $home-grey-accent 51%, $home-grey-accent 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $accent3 0%,$accent3 50%, $home-grey-accent 51%, $home-grey-accent 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $accent3 0%,$accent3 50%, $home-grey-accent 51%, $home-grey-accent 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a0af', endColorstr='#fafafa',GradientType=1 ); /* IE6-9 */
        .container {
            display: flex;
            flex-direction: row;

            > .content-container:first-of-type {
                width: 35%;
                padding: 7.5rem 5% 7.5rem 1.5rem;
            }

            > .content-container:last-of-type {
                width: 60%;
                padding: 7.5rem 1.5rem 7.5rem 2.5rem;
            }
        }
    }
}
@media screen and (min-width: 1280px) {
    .forty-sixty-container {

        .container {

            > .content-container:first-of-type {
                width: 35%;
                padding: 7.5rem 5% 7.5rem 0;
            }

            > .content-container:last-of-type {
                width: 60%;
                padding: 7.5rem 0 7.5rem 2.5rem;
            }
        }

        .fiftyfifty-container {
            width: 100%;
            padding-left: 4.5rem;

            .content-container {
                width: auto;
                min-width: 38%;

                &:nth-of-type(2) {
                    margin-left:10%;
                }
            }
        }
    }
}
@media screen and (min-width: 1440px) {
    .forty-sixty-container {

        .fiftyfifty-container {

            .content-container {
                min-width: 50%;

            }
        }
    }
}
