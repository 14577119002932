﻿
.tile-row {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    .tile-group {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        padding: 1% 0;

        .tile {
            box-sizing: border-box;
            width: 48%;
            height: 15rem;
            margin-left: 2%;
            border: 1px solid $sidebar-grey;
            text-align: center;
            align-items: center;
            display: flex;
            order: 1;
            background: $white;
            transition: $transition;

            &:first-of-type {
                margin-left: 1%;
            }

            a {
                width: 100%;
                height: 100%;
                align-items: center;
                justify-content: center;
                flex-wrap: wrap;
                display: flex;
                flex-direction: column;
                color: $dark-accent1;

                &:hover {
                    text-decoration: none;
                    background: rgba(238,53,36, 0.2);
                }

                span {
                    font-weight: 600;
                    font-size: 1.8rem;
                }
            }

            i {
                color: $accent1;
                display: block;
                padding: 1.5rem;
                font-size: 4.4rem;
            }

            .selected {
                background: $accent1;
                color: $white;
                position: relative;


                i {
                    color: $white;
                }

                &:after {
                    font-family: $font-awesome;
                    content: '\f0d7';
                    color: $white;
                    position: absolute;
                    bottom: 2.5rem;
                    font-size: 2.8rem;
                    left: 47%;
                    font-weight: 800;
                }
            }

            &.bgimage {
                background-size: cover;

                a {
                    color: $white;
                }

                .selected {
                    background: rgba(238,53,36, 0.8);
                }
            }

            &.double-wide {
                width: 98%;
                flex-direction: row;
                text-align: left;
                padding: 2.5rem;
                justify-content: space-between;
                flex-wrap: wrap;
                height: auto;

                .title-description {

                    p {
                        font-size: 1.4rem;
                        line-height: 2rem;
                    }
                }

                .top-group,
                .bottom-group,
                .tile-buttons {
                    width: 100%;

                    a {
                        height: auto;
                        margin-bottom: 0.5rem;

                        &:hover {
                            color: $white;
                        }
                    }

                    .secondary-button {
                        outline-color: transparent;

                        &.transparent {
                            outline-color: $white;
                            color: $white;
                        }
                    }
                }
            }
        }


        .group-expansion {
            height: 0;
            display: none;
            order: 10;
            background: $home-grey-accent;
            transition: $transition;

            .title {
                font-size: 1.6rem;
                font-weight: 700;
                font-family: $ibm;
                color: $dark-accent1;
                margin-top: 1.5rem;
                transition: $transition;
            }

            ul {
                list-style: none;
                padding-inline-start: 0;

                li {
                    line-height: 3.1rem;

                    a {
                        color: $white;

                        &:hover {
                            color: $accent1;
                        }
                    }
                }
            }


            &.expanded {
                margin: 1%;
                padding: 0 1.5rem;
                height: auto;
                width: 100%;
                display: block;
                border-top: 8px solid $accent1;

                .title,
                ul li a {
                    color: $dark-accent1;
                }
            }
        }

        &.full-width {
            .tile {
                margin-bottom: 2%;
            }

            .tile:nth-of-type(5) {
                margin-left: 1%;
                order: 9;
            }

            .tile:nth-of-type(7) {
                order: 9;
            }

            .group-expansion:nth-of-type(2),
            .group-expansion:nth-of-type(4) {
                order: 3
            }
        }
    }
}


@media screen and (min-width: 768px) {

    .tile-row {
        padding: 0 2.5rem;
        box-sizing: border-box;

        .tile-group {

            .tile {
                height: 25rem;

                &.selected {
                }
            }

            .tile.double-wide {
                .tile-buttons {
                    width: 42%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    height: 100%;
                    flex-wrap: wrap;
                }

                .title-description {
                    max-width: 50%;
                }
            }
        }
    }
}

@media screen and (min-width: 990px) {
    .tile-row {
        .tile-group {
            width: 50%;

            .group-expansion {
                .link-group {
                    width: 25%;
                }

                &.expanded {
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            .tile {
                .title-description {
                    max-width: 30.5rem;
                }

                &.double-wide {
                    height: 25rem;
                }
            }

            &.full-width {
                width: 100%;

                .tile {
                    width: 24%;
                    margin-left: 1%;
                }

                .tile:first-of-type {
                    margin-left: 0.5%;
                }

                .tile:nth-of-type(2) {
                    margin-left: 1%;
                }

                .group-expansion:nth-of-type(2),
                .group-expansion:nth-of-type(4) {
                    order: 11
                }
            }
        }
    }
}


@media screen and (min-width: 1188px) {
    .tile-row {
        flex-wrap: nowrap;
        padding: 0 0 1% 0;

        .tile-group {
            padding: 0;
        }
    }
}
