﻿.four-column-container {
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .content-area {
        padding-right: 1.5rem;

        img {
            max-width: 100%;
        }

        &:last-of-type {
            padding-right: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .four-column-container {
        flex-direction: row;
        padding-left: 0;
        padding-right: 0;

        .content-area {
            width: 25%;

            margin-right: 5%;
            margin-top: 3%;
            margin-bottom: 0%;

            &:last-of-type {
                margin-right: 0%;
            }
        }
    }
}