﻿.inline-with-form {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

@media screen and (min-width: 768px) { 
    .inline-with-form {
        padding-left: 0;
        padding-right: 0;
    }
}