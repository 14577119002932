﻿.blocked-content-container {
    background: $white;
    padding: 2.5rem 1.5rem; 
    box-sizing:border-box;
}


    @media screen and (min-width: 768px) {


        .blocked-content-container {
            width: 50%;
            background: $white;
            padding: 5rem 3rem;
            box-shadow: 0px 3px 36px rgba(0,0,0,.1);

            &.right {
                margin-left: 50%;
            }
        }
    }
