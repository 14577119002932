﻿.three-column-container {
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    .content-area {
        padding-right: 1.5rem;

        img {
            max-width: 100%;
        }

        &:last-of-type {
            padding-right: 0;
        }
    }
}

@media screen and (min-width: 768px) {
    .three-column-container {
        flex-direction: row;
        padding-left: 0;
        padding-right: 0;

        .content-area {
            width: 33%;

            margin-top: 5%;
            margin-bottom: 5%;

            &:first-of-type {
                margin-right: 5%;
            }

            &:last-of-type {
                margin-left: 5%;
            }
        }
    }
}