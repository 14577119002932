﻿a:hover i.fa-facebook-f,
a:hover i.fa-pinterest,
a:hover i.fa-twitter,
a:hover i.fa-linkedin,
a:hover i.fa-instagram {
    /*color: #517fa4;*/
    background-color: $accent1;
    color: $white!important;
    padding: 1.3rem 1rem;
}
  