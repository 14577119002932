footer {
    background-color: $white;
    color: $footer-light-grey;
    margin-top: 5rem;

    .footer-container {
        display: flex;
        flex-direction: column-reverse;
    }

    .link-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;

        .link-row {
            display: flex;
            flex-flow: column wrap;
            padding-bottom: 4rem;
            width: 50%;

            .category {
                font-family: $gotham;
                font-weight: 200;
                font-size: 1.5rem;
                padding-bottom: 1rem;
                padding-left: 1rem;
            }

            ul {
                list-style-type: none;
                padding-left: 1.5rem;

                li {
                    padding-bottom: 1rem;

                    a {
                        font-family: $ibm;
                        font-weight: 300;
                        font-size: 1.3rem;
                        transition: $transition;

                        &:focus {
                            outline-color: $accent1;
                        }

                        &:hover {
                            color: $accent1;
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .action-logo-container {
        .logo {
            padding-left: 1.5rem;
            padding-bottom: 2rem;
            max-width: 25rem;

            img {
                width:100%;
            }
        }
    }

    .contact-info-container {

        .footer-content {
            display: none;
        }

        .description {
            display: none;

            p {
                font-size: 1.6rem;
            }
        }


        p {
            font-family: $ibm;
            color: $footer-grey-brown;
            padding-left: 1.5rem;
            font-size: 1.3rem;
            margin-block-start: 0;
            margin-block-end: 0;

            a {
                text-decoration: none;

                &:focus {
                    outline-color: $accent1;
                }

                &:hover {
                    color: $accent1;
                    text-decoration: underline;
                }
            }
        }
    }

    .action-bar {
        display: block;
        padding-top: 3rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;

        input {
            border-width: 0;
            background-color: $footer-action-background;
            font-family: $ibm;
            font-size: 1.4rem;
            padding: .5rem 0 .5rem 1rem;
            width: 100%;
        }

        .text {
            font-family: $ibm;
            font-size: 1.4rem;
            padding-bottom: .5rem;
            color: $dark-accent1;
        }

        .small-button {
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    }

    .media-link-container {
        ul {
            display: flex;
            flex-direction: row;
            justify-content: center;
            list-style-type: none;
            padding-left: 1.5rem;
            margin-block-end: 0;
            margin-top: 0;
            margin-bottom: 0;

            li {
                padding-right: 2.4rem;

                a {
                    transition: $transition;

                    i {
                        color: $black;
                        padding: 1.3rem 1rem;
                        transition: $transition;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }

    .bottom-link-container {
        background-color: $footer-lightest-grey;

        ul.policy-links {
            display: flex;
            flex-direction: row;
            list-style-type: none;
            justify-content: center;
            border-top: .7rem solid $footer-lightest-grey;
            padding-left: 0;

            li:last-child a {
                border-right: 0;
            }

            li {
                padding-top: 2.3rem;

                a {
                    padding-left: .5rem;
                    padding-right: .5rem;
                    font-family: $ibm;
                    font-size: 1.3rem;
                    border-right: 1px solid $footer-light-grey;

                    &:focus {
                        outline-color: $accent1;
                    }

                    &:hover {
                        color: $accent1;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1080px) {
    footer {
        .footer-container {
            display: flex;
            flex-direction: column-reverse;
            padding: 0 1.5rem;

            .link-container {
                justify-content: space-between;
                flex-direction: row;

                .link-row {
                    width: 15%;

                    .category {
                        padding-left: 0;
                    }

                    ul {
                        padding-left: 0;
                    }
                }
            }

            .action-logo-container {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-end;
                order: 1;
                padding-bottom: 5rem;

                .logo {
                    padding-left: 0;
                    padding-bottom: 0;
                }

                .action-bar {
                    display: inline;
                    width: 30%;

                    input {
                        border-width: 0;
                        background-color: $footer-action-background;
                        font-family: $ibm;
                        font-size: 1.4rem;
                        padding-left: 1rem;
                        height: 50%;
                        width: 40%;
                    }

                    .text {
                        font-family: $ibm;
                        font-size: 1.4rem;
                        padding-bottom: .5rem;
                        color: $dark-accent1;
                    }
                }
            }
        }

        .contact-info-container {
            padding-left: 0;
            padding-bottom: 1rem;
            padding-right: 5rem;
            width: 30%;

            .description {
                line-height: 2.5rem;
                display: block;
                padding-left: 0;
                padding-top: 0;
                padding-bottom: 1.5rem;
            }


            p {
                padding-left: 0;
            }
        }

        .media-link-container {
            padding-left: 0;

            ul {
                list-style-type: none;
                margin-block-start: 0rem;
                margin-block-end: 0rem;

                li {
                    display: inline;
                    height: 100%;

                    a {
                        color: $footer-light-grey;
                        transition: $transition;

                        i {
                            padding: 1.8rem 1rem;
                            transition: $transition;

                            &:hover {
                                background-color: $accent1;
                                color: $white !important;
                                padding: 1.8rem 1rem;
                            }
                        }
                    }
                }
            }
        }

        .bottom-link-container {
            padding: 0 1.5rem;

            .container {
                display: flex;
                justify-content: space-between;

                ul.policy-links {
                    border-top: 0;
                    margin: 0;
                    padding-left: 0;

                    li {
                        padding-top: 1.5rem;
                        padding-bottom: 1.5rem;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1250px) {
    footer {
        .footer-container {
            padding: 0;

            .action-logo-container {
                .action-bar {
                    input {
                        border-width: 0;
                        background-color: $footer-action-background;
                        font-family: $ibm;
                        font-size: 1.4rem;
                        padding-left: 1rem;
                        height: 50%;
                        width: 66%;
                    }
                }
            }
        }
        .bottom-link-container {
            padding:0;
        }
    }
}
