﻿.nav-sitemap {
    margin: 2.5rem;
    padding-inline-start: 0;
    list-style: none;

    li {
        padding: 0.75rem 1rem;
    }

    ul {
        list-style: none;
        padding-inline-start: 1rem;
    }
    
    .root > a {
        border-bottom: 1px solid $sidebar-grey;
        font-size: 1.8rem;
        line-height: 2.4rem;
        font-weight: 700;

        &:hover {
            border-bottom: 1px solid transparent;
        }
    }
}
