﻿.sfPageEditor {

    header {
        position: relative;
        padding-bottom: 300px;

        .top-hat > div,
        nav {
            display: block;
        }

        nav > div:not(.mobile-toggle),
        nav > ul {
            display: inline-block;
        }

        nav ul td div {
            display: flex;
        }

        .loginPanel {
            width: 50%;
            padding-top: 80px;
        }
    }

    .white {
        color: $white;
        background-color: $accent5;
    }

    .modal-dialog form .form-group input[type=radio], form .input-wrapper input[type=radio], form .sf-fieldWrp input[type=radio] {
        position: relative;
        opacity: 1;
    }
}

//editor styling
.sfPageWrapper {
    .image-wrapper {
        max-width: 30%;
    }


}