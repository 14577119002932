﻿.welcome {
    padding: 3rem 2.5rem 3rem;

        h1 {
            margin-top: 1rem;
            padding-top: 1rem;
        }
    
}

.portal-nav {
    padding: 2.5rem;
    box-sizing: border-box;

    .container {
        .mobile-portal-toggle {
            border: 1px solid $white;
            display: block;
            padding: 1rem;
            width: 100%;
            color: $white;
            position: relative;
            box-sizing: border-box;

            &:after {
                content: '\f0c9';
                font-family: $font-awesome;
                font-weight: 800;
                position: absolute;
                right: 4%;
                top: 22%;
                color: $white;
                font-size: 2rem;
            }
        }

        ul {
            list-style: none;
            visibility: hidden;
            height: 0;
            margin-block-start: 0rem;
            margin-block-end: 0rem;
            padding: 0;

            &.open {
                visibility: visible;
                height: auto;
                border: 1px solid $white;
            }

            li {
                a {
                    color: $white;
                    transition: none;
                    padding: 1rem 2rem;
                    display: block;
                }

                &.selected {
                    a {
                        color: $accent1;
                    }
                }

                ul {
                    padding: 0 1rem;
                }
            }

            .hasDropdown:after {
                content: "\f0d7";
                font-family: $font-awesome;
                font-weight: 700;
                display: block;
                color: $white;
                position: absolute;
                right: 10%;
                top: 1rem;
                transition: $transition;
            }
        }

        .hasDropdown {
            position: relative;

            &.open {

                .dropdown {
                    visibility: visible;
                    height: auto;
                }

                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }
}


@media screen and (min-width: 1280px) {
    .welcome {
        padding: 3rem 0 3rem;
    }

    .portal-nav {
        padding: 0;
        border-top: 1px solid $white;
        margin-top: -1px;

        .container {
            .mobile-portal-toggle {
                display: none;
            }

            .portal-nav-list {
                visibility: visible;
                height: auto;
                display: flex;
                justify-content: flex-start;
                padding-inline-start: 0rem;

                li {

                    &:first-of-type {
                        padding-left: 0rem;
                    }

                    padding-left: 4rem;
                    padding-right: 4rem;
                    position: relative;

                    a {
                        padding: 2.5rem 0;
                    }

                    &.selected {
                        a {
                            color: $white;
                            border-top: 5px solid $white;
                            padding-top: 2rem;
                        }
                    }

                    ul {
                        padding-inline-start: 0rem;
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        background: $black;
                        padding: 2rem;

                        li {
                            &:first-of-type {
                                padding-left: 1rem;
                            }

                            padding: 1rem;
                            box-sizing: border-box;
                            width: 100%;
                            min-width: 15rem;

                            a {
                                padding: 0.5rem;
                            }
                        }
                    }

                    &.hasDropdown:after {
                        top: 2.5rem;
                    }
                }

                &.open {
                    border: none;
                }
            }
        }
    }
}
