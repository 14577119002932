﻿.blog-listing-container {
    display: flex;
    flex-direction: column;

    .featured-wrapper {
        border: 1px solid $sidebar-grey;
        display: flex;
        flex-direction: column;

        .image-wrapper {
            display: flex;

            .image {
                width: 100%;
                background-size: cover;
                height: 190px;
            }
        }

        .content-wrapper {
            margin-left: 2.5%;
            margin-right: 2.5%;
            margin-bottom: 10%;
            display: flex;
            flex-direction: column;
            height: 100%;

            a {
                font-family: $ibm;
                font-size: 1.4rem;
                color: $dark-accent1;
                text-decoration: none;
                font-weight: 600;
            }

            .category {
                margin-top: 5%;
                margin-bottom: 5%;
            }

            .title {
                a {
                    font-size: 1.8rem;
                }
            }

            .content {
                flex-basis: 100%;
            }

            .learn-more {

                a {
                    color: $dark-teal;
                    font-size: 1.4rem;
                    text-decoration: none;

                    i {
                        color: $dark-teal;
                        padding-left: .5rem;
                        transition: $transition;
                    }

                    &:hover {
                        text-decoration: underline;
                        color: $accent1;

                        i {
                            color: $accent1;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .blog-listing-container {
        flex-direction: column;

        .featured-large-container {
            margin-bottom: 2%;

            .featured-wrapper {
                .image-wrapper {
                    .image {
                        height: 288px;
                    }
                }
            }
        }

        .featured-small-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            .featured-wrapper {
                width: 49%;
                margin-bottom: 2%;
            }
        }

        .featured-wrapper {
            margin-left: 0;
            margin-bottom: 0;
        }
    }
}