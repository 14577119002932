﻿#registration-modal {
    min-width: 34rem;

    .container {
        max-width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        height: calc(100vh - 2rem);

        .registration-input {
            .toggle-password {
                float: right;
                margin-left: -3rem;
                margin-right: 2rem;
                margin-top: 1.5rem;
                position: relative;
                z-index: 2;
                color: $placeholer-text;
            }

            &.password-reqs {
                vertical-align: top;
                padding-top: 1.2rem;
            }

            #passwordRequirements {
                font-size: 1.4rem;
                color: $form-label;

                li {
                    ul {
                        padding-left: 2rem;

                        li {
                            list-style-type: circle;
                        }
                    }
                }
            }

            #lengthError,
            #upperCaseError,
            #lowerCaseError,
            #numberError,
            #symbolError {
                color: $accent1;
                font-size: 1.2rem;
                margin-block-start: 0;
                margin-block-end: 0;
                line-height: 1.2;
            }
        }

        h2 {
            color: $accent1;
            font-weight: 600;
            background: $white;
            margin: 0;
            padding: 3rem;
        }

        form {
            background: $white;
            padding: 3rem;
        }

        label {
            display: block;
            padding: 1rem 0 0.75rem;
        }

        input {
            width: calc(100% - 1rem);
        }

        fieldset {
            border: 0;
            margin: 0;
            padding: 1rem 0;

            label {
                padding: 0;
            }
        }

        .full-width-input {
            width: 100%;
            max-width: 100%;

            textarea {
                width: calc(100% - 2rem);
                min-height: 8.5rem;
            }
        }

        .secondary-button {
            padding: 1.1rem 0;
            width: 47%;
        }

        .button {
            width: 47%;
            margin-left: 2%;
        }
    }
}


@media screen and (min-width: 1080px) {

    #registration-modal {
        min-width: 80rem;


        .container {
            max-width: 65rem;
            padding-left: 4rem;

            .registration-input {
                display: inline-block;
                width: 49%;
                max-width: 100%;
            }
        }
    }
}
