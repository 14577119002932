﻿.featured-blog-container {
    display: flex;
    flex-direction: column;

    .featured-wrapper {
        margin-left: 2%;
        margin-right: 2%;
        margin-bottom: 2%;
        border: 1px solid $sidebar-grey;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;

        .image-wrapper {
            img {
                max-width: 100%;
            }
        }

        .content-wrapper {
            margin-left: 2.5%;
            margin-right: 2.5%;
            margin-bottom: 10%;
            display: flex;
            flex-direction: column;
            height: 100%;

            a {
                font-family: $ibm;
                font-size: 1.4rem;
                color: $dark-accent1;
                text-decoration: none;
                font-weight: 600;
            }

            .category {
                margin-top: 5%;
                margin-bottom: 5%;
            }

            .title {
                a {
                    font-size: 1.8rem;
                }
            }

            .content {
                flex-basis: 100%;
            }

            .learn-more {
                display: flex;
                align-items: center;

                a {
                    color: $dark-teal;
                    font-size: 1.4rem;
                    text-decoration: none;

                    i {
                        color: $dark-teal;
                        padding-left: .5rem;
                    }

                    &:hover {
                        transition: $transition;
                        text-decoration: underline;
                        color: $accent1;

                        i {
                            color: $accent1;
                            transition: $transition;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .featured-blog-container {
        flex-direction: row;
        .featured-wrapper {
            margin-left: 0;
            margin-bottom: 0;
        }
    }
}